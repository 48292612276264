<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom  gutter-b col-md-12">
    <!--begin::Header-->
    <!-- <div class="card-header border-0 pt-5">
      <h3 class="card-title font-weight-bolder">QuickBooks Token</h3>
    </div> -->
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="text-center">
        <p class="text-center">Your QuickBooks token for the CS will expire on</p>
        <h2 class="text-center font-weight-bolder pb-7">  
          {{ refershTokenExCS }}
        </h2>
       
        <p class="text-center">Your QuickBooks token for the Six will expire on</p>
        <h2 class="text-center font-weight-bolder pb-7">  
          {{ refershTokenExSix }}
        </h2>
       
        <a
          class="btn btn-success btn-shadow-hover font-weight-bolder py-3"
          @click="callGetQBToken"
          >Refresh Token</a
        >
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 14-->
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, child, get } from "firebase/database";
import moment from 'moment';

const functions = getFunctions();
const dbRef = ref(getDatabase());
export default {
  name: "widget-7",
  data() {
    return {
      chartOptions: {},
      series: [74],
      refershTokenExSix: null,
      refershTokenExCS: null,
    };
  },
  components: {},
  computed: {},
  methods: {
    async callGetQBToken() {
      let getQBToken = httpsCallable(functions, "getQBToken");
      await getQBToken()
        .then((response) => {
          location.href = response.data;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async getExDateSix() {
      get(child(dbRef, 'qbtoken/9130357463137456'))
        .then((snapshot) => {
          if (snapshot.exists()) {

            this.refershTokenExSix = moment(snapshot.val().createdat).add(snapshot.val().x_refresh_token_expires_in,'seconds');
     
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getExDateCS() {
      get(child(dbRef, 'qbtoken/9130351962560356'))
        .then((snapshot) => {
          if (snapshot.exists()) {

            this.refershTokenExCS = moment(snapshot.val().createdat).add(snapshot.val().x_refresh_token_expires_in,'seconds');
     
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {},
  created() {
    this.getExDateSix();
    this.getExDateCS();
  },
};
</script>